import React, { useState } from "react";
import styles from "./ModalEnvioQuestionarioComponent.module.css";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { MdOutlineFactCheck } from 'react-icons/md';
import api from '../../service/api';
import logoHB4 from '../../assets/images/hb4_principal.png'
import { useParams } from "react-router"; // Para capturar o envio_id da URL

function ModalEnvioQuestionario(props) {

    const [isOpen, setIsOpen] = useState(true);
    const respostas = props.respostas;
    const { id } = useParams(); // Captura o envio_id da URL

    // Função que lida com o envio das respostas
    const handleSubmit = (respostas) => {
        // Primeiro, envia as respostas do questionário
        api.post('perguntas/', {
            respostas,
            envio_id: id
        }).then((response) => {
            // Após o envio das respostas, chama a nova API para registrar o envio do questionário
            api.post('/questionarioRespondido', {
                envio_id: id
            }).then(() => {
                console.log("Envio registrado com sucesso.");
            }).catch((error) => {
                console.error("Erro ao registrar envio:", error);
            });
                // Fecha o modal e redireciona o usuário
                setIsOpen(false);
                window.location.assign("https://www.google.com");
            }).catch((error) => {
                console.error("Erro ao enviar respostas:", error);
            });
    };

    return (
        <div>
            <Modal
                centered
                size=""
                isOpen={isOpen}
            >
                <ModalHeader className={styles.modalHeader}>
                    <img src={logoHB4} className={styles.logoHB4} />
                </ModalHeader>
                <ModalBody>
                    <div className={styles.icon} >
                        <MdOutlineFactCheck size="80" className={styles.icon} />
                    </div>
                    <div className={styles.divText}>
                        Obrigado por responder o questionário!
                    </div>
                </ModalBody>
                <ModalFooter className={styles.modalFooter}>
                    <Button size="sm" onClick={() => handleSubmit(respostas)} className={styles.button}>
                        Enviar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalEnvioQuestionario;